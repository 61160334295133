import React, { useContext, useEffect, useState } from 'react';
import Images from 'utils/Images.js';
import { ActionPopupContext } from 'contexts/Action_Modal_Context';
import ActionButton from 'components/buttons/Action_Button';
import { debounce, InputLabel, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function ConfirmActionModal() {
    const [error, setError] = useState(false);
    const { isActionPopupOpened, handleActionPopupOpen, actionData, setActionData } = useContext(ActionPopupContext);
    const handleNumbersChange = (e) => {
        const value = e.target.value;
        const regex = /^\d+$/;

        if (regex.test(value)) {
            setError(false)
            setActionData({ ...actionData, amount: value });
        } else {
            setError(true)
            e.target.value = '';
        }
    };
    return (
        <div
            className={`confirm-action-modal ${isActionPopupOpened ? 'open' : ''}`}>

            <div className={`confirm-action-modal-body ${actionData?.type}`}>
                <div>
                    <button
                        onClick={() => { handleActionPopupOpen({ isOpened: false }); }}
                        className="button-hidden confirm-action-modal-close-btn">
                        <img
                            src={Images.imageURL('close.svg')}
                            alt="Close modal"
                            width="18"
                            height="18"
                            className="confirm-action-modal-close-icon"
                        />
                    </button>
                    <h4 className={`confirm-action-modal-heading ${actionData?.type}`}>{actionData?.heading}</h4>
                    <p>{actionData?.text}</p>
                </div>

                {actionData?.type === 'note' ?
                    <>
                        <TextField
                            onChange={(e) => {
                                setActionData({ ...actionData, header: e.target.value });
                            }}
                            defaultValue={actionData?.header}
                            inputProps={{ maxLength: 50 }}
                            id="outlined-basic" variant="outlined" />
                        <TextField
                            onChange={(e) => {
                                setActionData({ ...actionData, note: e.target.value });
                            }}
                            defaultValue={actionData?.note}
                            style={{ marginBottom: 23 }}
                            id="outlined-multiline-flexible"
                            multiline
                            inputProps={{ maxLength: 1000 }}
                            minRows={4}
                            />
                    </>
                    : (actionData?.type === 'delete_user' || actionData?.type === 'suspend_user') ?
                        <>
                            {actionData?.currentComments ?
                                <p>Previous comments: {actionData?.currentComments}</p>
                                : <></>
                            }
                            <TextField
                                placeholder='Add some comment'
                                onChange={debounce((e) => {
                                    setActionData({ ...actionData, adminComments: e.target.value });
                                },300)}
                                style={{ marginBottom: 23 }}
                                id="outlined-multiline-flexible"
                                multiline
                                inputProps={{ maxLength: 1000 }}
                                minRows={4}
                            />
                        </>
                    : actionData?.type === 'bonus' ?
                        <>
                            <InputLabel htmlFor="outlined-basic">Amount</InputLabel>
                            <TextField
                                error={error}
                                onChange={handleNumbersChange}
                                defaultValue={actionData?.amount}
                                id="outlined-basic"
                                variant="outlined"
                                type='number'
                            />
                            <InputLabel htmlFor="reason-field">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="coins-type"
                                value={actionData.amountType}
                                onChange={(e) => {
                                    setActionData({ ...actionData, amountType: e.target.value });
                                }}
                            >
                                {actionData.dropdown.map(i => <MenuItem value={i.value}>{i.label}</MenuItem>)}
                            </Select>
                            <InputLabel htmlFor="reason-field">Reason</InputLabel>
                            <TextField
                                onChange={(e) => {
                                    setActionData({ ...actionData, reason: e.target.value });
                                }}
                                multiline
                                id="reason-field" variant="outlined" />
                        </>
                        : <></>
            }

                <div className="confirm-action-modal-buttons">
                    <ActionButton
                        onClick={() => { handleActionPopupOpen({ isOpened: false }); }}
                        type="cancel"
                        text="Cancel"
                        className={'width-185'}
                    />
                    <ActionButton
                        onClick={() => {
                            actionData.handleConfirm(actionData);
                            handleActionPopupOpen({ isOpened: false })
                        }}
                        className={'width-185'}
                        type="edit"
                        text={actionData?.confirmBtnText || 'OK'}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConfirmActionModal;