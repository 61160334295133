import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiGetUserProfileInfo, apiUserGiveBonus } from 'services/UserProfileService.js';
import { suspendUserHandler, deleteUserHandler } from 'services/UsersService';
import { ActionPopupContext } from 'contexts/Action_Modal_Context';
import { UserContext } from 'contexts/UserContext.js';
import { apiHandleUserNotes } from 'services/UserProfileService.js';
import Box from '@mui/material/Box';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BlockIcon from '@mui/icons-material/Block';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import LinkIcon from '@mui/icons-material/Link';
import Images from 'utils/Images.js';
import NoteCard from './components/NoteCard';
import ActionButton from 'components/buttons/Action_Button';
import Alerts from 'utils/Alerts';
import { dropdownBonuses } from './helpers/constants.js'

const content = require('content.json');
const configuration = require('configuration.json');
const userStatues = configuration.user_statuses;

const ProfileInfo = ({ user, onTabChange }) => {
    const { handleActionPopupOpen } = useContext(ActionPopupContext);
    const { setUser } = useContext(UserContext);

    const [profileData, setProfileData] = useState(null);
    const [loader, setLoader] = useState(false);
    let [searchParams] = useSearchParams();
    const uniqueUserID = searchParams.get('uniqueUserID') || user.uniqueID;

    const fetchData = async () => {
        setLoader(true);
        const data = await apiGetUserProfileInfo(uniqueUserID);
        setUser({ ...user, ...data });
        if (user.scrollDown) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        }
        setLoader(false);
        if (data) {
            setProfileData(data);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const addOrUpdateNote = async (data) => {
        setLoader(true);
        await apiHandleUserNotes({ ...data, uniqueUserID });
        fetchData();
        setLoader(false);
    }

    const giveBonusHandler = async (data) => {
        setLoader(true);
        await apiUserGiveBonus({ ...data, uniqueUserID, language: user.language })
        setLoader(false);
    }

    return (<div className='user-page-container'>
        {loader ?
            <Box className="loader-mask">
                <CircularProgress className="loader" />
            </Box>
            : <></>}
        {profileData ?
            <>
                {profileData?.treatCoins ? <>
                <p className="user-profile-category-heading">Treat Coins</p>
                    <div className="user-profile-list coins">
                        {Object.keys(profileData.treatCoins).map((i, idx) => <div className='profile-card' onClick={() => onTabChange('', '2', i, 'tc_feed')}>
                        <div className="flex justify-between margin-bottom-20 " >
                                <img className='profile-card-tc' src={Images.imageURL('user/tc_image.png')} alt="" />
                            <div className="flex align-self-start">
                                    <p className='profile-card-desc'>See feed</p>
                                <ArrowForwardIcon />
                            </div>
                        </div>
                            <p className='profile-card-desc bold'>{content.user_profile[i]}</p>
                            <p className='profile-card-desc number'>{profileData.treatCoins[i]}</p>
                    </div>)}
                </div>
                </>
                    : <></>}
                {profileData?.referralsInfo ?
                    <>                <p className="user-profile-category-heading">Referrals</p>
                        <div className="user-profile-list">
                            {Object.keys(profileData?.referralsInfo).map((i, idx) => <div className='profile-card referrals' onClick={() => onTabChange('', '3', i, 'referrals')}>
                                <div className="flex justify-between margin-bottom-20 " >
                                    <img className='profile-card-tc referrals' src={Images.imageURL(`user/referrals${idx}.png`)} alt="" />
                                    <div className="flex align-self-start">
                                        <p className='profile-card-desc'>See referrals</p>
                                        <ArrowForwardIcon />
                                    </div>
                                </div>
                                <p className='profile-card-desc bold'>{content.user_profile[i]}</p>
                                <p className='profile-card-desc number  referrals'>{profileData.referralsInfo[i]}</p>
                            </div>
                            )}
                        </div>
                    </>
                    : <></>}
                {profileData?.accountStatus ?
                    <>
                        <p className="user-profile-category-heading">Account info</p>
                        <div className="user-profile-list activity">
                            {Object.keys(profileData.accountStatus).map(i => <div className='profile-card' >
                                <div className="profile-card-bg-image"
                                    style={{ backgroundImage: `url(${Images.imageURL(`user/${i}.png`)})` }} >
                                </div>
                                <p className='profile-card-desc bold'>{content.user_profile[i]}</p>
                                <p className='profile-card-desc activity'>{profileData.accountStatus[i]}</p>
                            </div>
                            )}
                        </div>
                    </>
                    : <></>}
                {profileData?.paymentMethods ?
                    <>
                        <p className="user-profile-category-heading">Payment info</p>
                        <div className="user-profile-list activity">
                            {profileData.paymentMethods.map(i =>
                                <div className='profile-card profile-payment-method-card' >
                                    <div className="profile-card-bg-image-payment"
                                        style={{ backgroundImage: `url(${Images.imageURL(`user/method_${i.paymentMethodType}.png`)})` }} >
                                    </div>
                                    <p className='profile-card-desc email'>{i.email}</p>
                                    <p className='profile-card-desc id'>{i.ID}</p>
                                </div>
                            )}
                        </div>
                    </>
                    : <></>}
                {profileData?.notes ?
                    <>
                        <button
                            onClick={() => {
                                handleActionPopupOpen({
                                    isOpened: true, actionData: {
                                        type: 'note',
                                        heading: 'Add Note',
                                        confirmBtnText: 'Submit',
                                        uniqueUserID, 
                                        handleConfirm: addOrUpdateNote
                                    }
                                });
                            }}
                            className="user-profile-category-heading">Notes &#9998;</button>
                        <div className="user-profile-list activity">
                            {profileData.notes.map(i =>
                                <NoteCard
                                    data={i}
                                    setLoader={setLoader}
                                    fetchData={fetchData}
                                    uniqueUserID={uniqueUserID}
                                />
                            )}
                        </div>
                    </>
                    : <></>}
            </>
            : <></>}
        <p className="user-profile-category-heading">Taking action</p>
        <div className="flex gap-10 ">
            <ActionButton
                onClick={() => { deleteUserHandler(setLoader, user.ID, handleActionPopupOpen); }}
                className='user-profile-action-btn justify-between' text='Delete user' type='edit'>
                <DeleteForeverIcon />
            </ActionButton>
            <ActionButton
                onClick={() => {
                    suspendUserHandler(setLoader, user.ID, userStatues.suspended, handleActionPopupOpen);
                }}
                className='user-profile-action-btn justify-between' text='Block user' type='edit'>
                <BlockIcon />
            </ActionButton>
            <ActionButton
                onClick={() => { suspendUserHandler(setLoader, user.ID, userStatues.active, handleActionPopupOpen); }}
                className='user-profile-action-btn justify-between' text='Unblock user' type='edit'>
                <SettingsBackupRestoreIcon />
            </ActionButton>
            <ActionButton
                onClick={() => { 
                    handleActionPopupOpen({
                        isOpened: true, actionData: {
                            dropdown: dropdownBonuses,
                            handleConfirm: giveBonusHandler,
                            type: 'bonus',
                            heading: 'Add Bonus',
                            confirmBtnText: 'Confirm',
                            add:true
                        }
                    })
                }}
                className='user-profile-action-btn justify-between' text='Give Bonus' type='edit'>
                <AddIcon />
            </ActionButton>
            <ActionButton
                onClick={() => {
                    handleActionPopupOpen({
                        isOpened: true, actionData: {
                            dropdown: dropdownBonuses,
                            handleConfirm: giveBonusHandler,
                            type: 'bonus',
                            heading: 'Fine User',
                            confirmBtnText: 'Confirm',
                            add: false
                        }
                    });
                }}
                className='user-profile-action-btn justify-between' text='Fine User' type='edit'>
                <CachedIcon />
            </ActionButton>
            {user?.referralLink ?
            <ActionButton
                    onClick={() => {
                        navigator.clipboard.writeText(user?.referralLink);
                        Alerts.showSuccessAlert('Copied');
                    }}
                    className='user-profile-action-btn justify-between' text='Copy ref link' type='edit'>
                <LinkIcon />
            </ActionButton>
                : <></>
            }
        </div>
    </div>);

};

export default ProfileInfo;