import { ActionPopupContext } from 'contexts/Action_Modal_Context';
import { format, parseISO } from 'date-fns';
import React, { useContext } from 'react';
import { apiHandleUserNotes } from 'services/UserProfileService.js';
import Formatting from 'utils/Formatting';

function NoteCard({ data, setLoader, fetchData, uniqueUserID }) {
  const { handleActionPopupOpen, } = useContext(ActionPopupContext);
  const { canEdit, dateCreated, header, note, noteID, author } = data;

  const addOrUpdateNote = async (data) => {
    setLoader(true);
    await apiHandleUserNotes({ ...data, uniqueUserID });
    fetchData();
    setLoader(false);
  }

  return (
    <div className='profile-card note'
      onClick={() => {
        if (canEdit) {
        handleActionPopupOpen({
          isOpened: true, actionData: {
            ...data,
            handleConfirm: addOrUpdateNote,
            type: 'note',
            heading: 'Edit Note',
            confirmBtnText: 'Submit'
          }
        })
        }
      }}
    >
      <div className="note-heading-container">
        <p className="note-heading-text">{header}</p>
        <p className="note-heading-date">{format(parseISO(dateCreated), 'd/M/yyyy HH:mm:ss')}</p>
      </div>
      <div className='flex-column justify-between hight-80'>
      <p className="note-text">
        {Formatting.shortenText(note, 100)}
        &#160;
        {note.length>100?
          <span className="underlined">Read more</span>
          :<></>
        }
      </p>
        <p className="note-author">{author} <span>&#9998;</span></p>
      </div>
    </div>
  );
}

export default NoteCard;